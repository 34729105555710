export const PUBLIC_ROUTES = {
  HOME: 'home',
  LIBRARY: 'library',
  MY_DOCUMENTS: 'my-documents',
  TRANSCRIPTIONS: {
    ROOT: 'transcriptions',
    CHILD: {
      DETAIL: 'detail',
    },
  },
  EMAIL_VALIDATION: {
    ROOT: 'email-validation',
    CHILDREN: {
      VALIDATE_EMAIL: 'validate-email',
      EMAIL_VERIFIED: 'email-verified',
    },
  },
  REGISTRATION: {
    ROOT: 'registration',
    CHILDREN: {
      PROFILE: 'profile',
      COMPANY: 'company',
      PRICING: 'pricing',
      INVITE: 'invite',
      COMPLETED: 'completed',
    },
  },
  DEVICES_LIMIT_REACHED: {
    ROOT: 'devices-limit-reached',
    CHILDREN: {
      DISCONNECT_DEVICES: 'disconnect-devices',
      VERIFICATION: 'verification',
    },
  },
  CONSULTATION: {
    ROOT: 'consultation',
    CHILDREN: {
      CREATE: 'create',
      MOBILE: 'mobile',
      LOADING: 'loading',
      DETAIL: 'detail',
      TEMP: 'temporary',
    },
  },
  CHAT_IFRAME: 'chat-deprecado',
  OLD_ROUTES: {
    CHAT: 'chat',
    CREATE_CONSULTATION: 'create-consultation',
    CONSULTATIONS_MOBILE: 'consultations',
  },

  AUTH: {
    ROOT: 'auth',
    CHILD: {
      LOGIN: 'login',
      LOGOUT: 'logout',
      NEW_ACCESS_DETECTED: 'new-access-detected',
    },
  },
}
