import { createSlice } from '@reduxjs/toolkit'
import { type IUser } from '../../../modules/auth/interfaces/IUser'
import { userReducer } from '../../reducers/user/UserReducer'

const initialState: IUser = {
  id: '',
  uuid: '',
  email: '',
  full_name: '',
  rol: null,
  is_active: false,
  token: '',
  onboarding_step: null,
  fingerPrint: '',
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: userReducer,
})

export const { setUser, setToken, setFingerPrint } = userSlice.actions

export default userSlice.reducer
