import { t } from 'i18next'
import { FILTERS_MOCK_RESPONSE } from '../../../../mocks/services/filters/FiltersMock'
import {
  type IFilterCategoryItemFromResponse,
  type IFilterSlice,
} from '../../../../redux/features/chatbot/chats-history/filters/IFilters'
import { type ICheckboxListOption } from '../../field/item/checkbox-list/interfaces/ICheckboxList'
import { type IFieldItemSelectPropsRequired } from '../../field/item/select-menu/container/interfaces/IFieldItemSelect'
import {
  type IHandleCounterInFilters,
  type IMapFiltersFromResponse,
  type IUseMapFiltersFromResponse,
} from './IUseMapFiltersFromResponse'

const useMapFiltersFromResponse = (): IUseMapFiltersFromResponse => {
  const mapFiltersFromReponse = ({
    defaultFiltersFromResponse,
    customFiltersFromResponse,
  }: IMapFiltersFromResponse): { FILTERS_MOCK_MAP: IFilterSlice } => {
    // Create a deep clone of the default filters
    const mergedDefaultFilters = {
      arielOrganization: {
        ...FILTERS_MOCK_RESPONSE.default_filters.arielOrganization,
      },
      customOrganization: {
        ...FILTERS_MOCK_RESPONSE.default_filters.customOrganization,
      },
      year: { ...FILTERS_MOCK_RESPONSE.default_filters.year },
      type: {
        ...FILTERS_MOCK_RESPONSE.default_filters.type,
        list: [...FILTERS_MOCK_RESPONSE.default_filters.type.list],
        activeList: [...FILTERS_MOCK_RESPONSE.default_filters.type.activeList],
      },
      topic: {
        ...FILTERS_MOCK_RESPONSE.default_filters.topic,
        list: [...FILTERS_MOCK_RESPONSE.default_filters.topic.list],
        activeList: [...FILTERS_MOCK_RESPONSE.default_filters.topic.activeList],
      },
    }

    // Update organization settings if provided
    if (defaultFiltersFromResponse) {
      // Handle both direct properties and IDefaultFilterFromResponse interface
      if ('search_on_public' in defaultFiltersFromResponse) {
        mergedDefaultFilters.arielOrganization.isOn =
          defaultFiltersFromResponse.search_on_public as boolean
      } else if ('arielOrganization' in defaultFiltersFromResponse) {
        mergedDefaultFilters.arielOrganization.isOn =
          defaultFiltersFromResponse.arielOrganization.isOn
      }

      if ('search_on_company' in defaultFiltersFromResponse) {
        mergedDefaultFilters.customOrganization.isOn =
          defaultFiltersFromResponse.search_on_company as boolean
      } else if ('customOrganization' in defaultFiltersFromResponse) {
        mergedDefaultFilters.customOrganization.isOn =
          defaultFiltersFromResponse.customOrganization.isOn
      }
    }

    // Ensure custom filters have organization values, using defaults if not provided
    const customArielOrgIsOn =
      customFiltersFromResponse?.search_on_public ??
      mergedDefaultFilters.arielOrganization.isOn
    const customCustomOrgIsOn =
      customFiltersFromResponse?.search_on_company ??
      mergedDefaultFilters.customOrganization.isOn

    if (!customFiltersFromResponse) {
      customFiltersFromResponse = FILTERS_MOCK_RESPONSE.custom_filters
    }

    const typeListWithAllOption = [
      { name: 'Todas las categorías', code: 'Todas_las_categorías' },
      ...mergedDefaultFilters.type.list,
    ]
    const topicListWithAllOption = [
      { name: 'Todas las áreas', code: 'Todas_las_áreas' },
      ...mergedDefaultFilters.topic.list,
    ]

    // TYPE
    const defaultTypeCategoriesMap: ICheckboxListOption[] = []
    const customTypeCategoriesActive: ICheckboxListOption[] = []
    const defaultTypeCategoriesActive: ICheckboxListOption[] = []

    typeListWithAllOption.forEach(
      (category: IFilterCategoryItemFromResponse) => {
        defaultTypeCategoriesMap.push({
          id: category.code,
          name: category.name,
        })

        if (customFiltersFromResponse?.category?.includes(category.code)) {
          customTypeCategoriesActive.push({
            id: category.code,
            name: category.name,
          })
        }

        if (category.name === t('CHECKBOX_OPTIONS.ALL_CATEGORIES')) {
          defaultTypeCategoriesActive.push({
            id: category.code,
            name: category.name,
          })
        }
      },
    )

    // TOPIC
    const defaultTopicCategoriesMap: ICheckboxListOption[] = []
    const customTopicCategoriesActive: ICheckboxListOption[] = []
    const defaultTopicCategoriesActive: ICheckboxListOption[] = []

    topicListWithAllOption.forEach(
      (category: IFilterCategoryItemFromResponse) => {
        defaultTopicCategoriesMap.push({
          id: category.code,
          name: category.name,
        })

        if (customFiltersFromResponse?.keywords?.includes(category.code)) {
          customTopicCategoriesActive.push({
            id: category.code,
            name: category.name,
          })
        }

        if (category.name === t('CHECKBOX_OPTIONS.ALL_TOPICS')) {
          defaultTopicCategoriesActive.push({
            id: category.code,
            name: category.name,
          })
        }
      },
    )

    let defaultYearFrom: IFieldItemSelectPropsRequired = { id: 0, text: '' }
    let defaultYearTo: IFieldItemSelectPropsRequired = { id: 0, text: '' }
    let customYearFrom: IFieldItemSelectPropsRequired | null = null
    let customYearTo: IFieldItemSelectPropsRequired | null = null

    const yearListMap = mergedDefaultFilters.year.list.map((year) => {
      if (year === mergedDefaultFilters.year.from) {
        defaultYearFrom = {
          id: year,
          text: String(year),
        }
      }
      if (year === mergedDefaultFilters.year.to) {
        defaultYearTo = {
          id: year,
          text: String(year),
        }
      }
      if (year === customFiltersFromResponse?.year_from) {
        customYearFrom = {
          id: year,
          text: String(year),
        }
      }
      if (year === customFiltersFromResponse?.year_to) {
        customYearTo = {
          id: year,
          text: String(year),
        }
      }
      return {
        id: year,
        text: String(year),
      }
    })

    const handleCountersInFilters = (): IHandleCounterInFilters => {
      let counterFiltersChosen = 0
      let counterType = 0
      let counterTopic = 0

      if (
        customFiltersFromResponse !== null &&
        customFiltersFromResponse instanceof Object &&
        Object.keys(customFiltersFromResponse).length > 0
      ) {
        if (
          customFiltersFromResponse.year_from !== undefined &&
          customFiltersFromResponse.year_from !== null &&
          !isNaN(Number(customFiltersFromResponse.year_from)) &&
          Number(customFiltersFromResponse.year_from) !==
            mergedDefaultFilters.year.from
        ) {
          counterFiltersChosen += 1
        }
        if (
          customFiltersFromResponse.year_to !== undefined &&
          customFiltersFromResponse.year_to !== null &&
          !isNaN(Number(customFiltersFromResponse.year_to)) &&
          Number(customFiltersFromResponse.year_to) !==
            mergedDefaultFilters.year.to
        ) {
          counterFiltersChosen += 1
        }

        if (
          customFiltersFromResponse.category !== undefined &&
          customFiltersFromResponse.category !== null
        ) {
          counterType = customFiltersFromResponse.category.length
          counterFiltersChosen += counterType
        }

        if (
          customFiltersFromResponse.keywords !== undefined &&
          customFiltersFromResponse.keywords !== null
        ) {
          counterTopic = customFiltersFromResponse.keywords.length
          counterFiltersChosen += counterTopic
        }
      }

      return {
        counterFiltersChosen,
        counterType,
        counterTopic,
      }
    }

    const { counterFiltersChosen, counterType, counterTopic } =
      handleCountersInFilters()

    const FILTERS_MOCK_MAP: IFilterSlice = {
      default: {
        counterFiltersChosen,
        arielOrganization: mergedDefaultFilters.arielOrganization,
        customOrganization: mergedDefaultFilters.customOrganization,
        year: {
          list: yearListMap,
          reversedList: [...yearListMap.reverse()],
          from: defaultYearFrom,
          to: defaultYearTo,
        },
        type: {
          list: defaultTypeCategoriesMap,
          activeList: defaultTypeCategoriesActive,
          counter: counterType,
        },
        topic: {
          list: defaultTopicCategoriesMap,
          activeList: defaultTopicCategoriesActive,
          counter: counterTopic,
        },
      },
      custom: {
        counterFiltersChosen,
        arielOrganization: {
          isOn: customArielOrgIsOn,
        },
        customOrganization: {
          isOn: customCustomOrgIsOn,
        },
        year: {
          from: customYearFrom,
          to: customYearTo,
        },
        type: {
          activeList:
            customTypeCategoriesActive.length > 0
              ? customTypeCategoriesActive
              : [],
          counter: counterType,
        },
        topic: {
          activeList:
            customTopicCategoriesActive.length > 0
              ? customTopicCategoriesActive
              : [],
          counter: counterTopic,
        },
      },
    }
    return {
      FILTERS_MOCK_MAP,
    }
  }
  return {
    mapFiltersFromReponse,
  }
}

export default useMapFiltersFromResponse
