import { type PayloadAction } from '@reduxjs/toolkit'
import { type IDevicesLimit } from '../../features/devices-limit/IDevicesLimit'
import { type IDeviceListItem } from '../../../core/components/devices-list/item/interfaces/IDevicesListItem'

export const devicesLimitReducers = {
  setValidDevice: (state: IDevicesLimit, action: PayloadAction<boolean>) => {
    state.validDevice = action.payload
  },

  setDeletedDevicesList: (
    state: IDevicesLimit,
    action: PayloadAction<IDeviceListItem | null>,
  ) => {
    if (action.payload === null) {
      state.deletedDevicesList = []
    } else {
      state.deletedDevicesList.push(action.payload)
    }
  },

  setDevicesList: (
    state: IDevicesLimit,
    action: PayloadAction<IDeviceListItem[]>,
  ) => {
    state.devicesList = action.payload
  },

  setEmailSent: (state: IDevicesLimit, action: PayloadAction<boolean>) => {
    state.isEmailSent = action.payload
  },
}
