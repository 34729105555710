import { type ColorsFromResponse } from '../../../../../modules/dashboard/pages/chat/consultation/enum/ColorsFromResponse'
import { type ChatbotMessageRols } from '../enum/ChatbotMessageTypes'

export type ISource = IPublicSource | ICompanySource

export interface IPublicSource {
  position: number
  score: number
  rerank: number
  captions: string
  id: string
  title: string
  author: string
  keywords: string
  category: string
  page: number
  year: number
  has_copyright: boolean
  file_path: string
  external_id: string
  content: string
  fragment_number: number
}

export interface ICompanySource {
  position: number
  score: number
  rerank: number
  captions: string
  id: string
  title: string
  category: string
  tags: string[]
  uploaded_by_id: string
  page: number
  year: number
  file_path: string
  external_id: string
  content: string
  fragment_number: number
}

export interface ISearchProcess {
  id: number
  searchQuery: string
  queryReason: string
  isPrivate: boolean
  results: ISource[]
}

export type MetadataDocumentInfo = null | {
  file_name: string
}

export interface IChatbotMessageMetadata {
  sources?: ISource[]
  firstSearch?: Record<number, ISearchProcess>
  secondSearch?: Record<number, ISearchProcess>
  allResultsCount?: number
  document?: MetadataDocumentInfo
}

export interface IChatbotMessage {
  uuid: string
  uuidChatOrigin: string
  type: ChatbotMessageRols.USER | ChatbotMessageRols.ASSISTANT
  avatarColor?: ColorsFromResponse
  prompt: string
  metadata: IChatbotMessageMetadata
  isTypingCompleted?: boolean
  isError?: boolean
}

export function isCompanySource(source: ISource): source is ICompanySource {
  return 'uploaded_by_id' in source || 'tags' in source
}

export function isPublicSource(source: ISource): source is IPublicSource {
  return 'author' in source || 'has_copyright' in source
}
