import { type IUser } from '../../../modules/auth/interfaces/IUser'
import { type PayloadAction } from '@reduxjs/toolkit'

export const userReducer = {
  setUser: (state: IUser, action: PayloadAction<IUser>) => {
    Object.entries(action.payload).forEach(([key, value]) => {
      state[key] = value
    })
  },

  setToken: (state: IUser, action: PayloadAction<string>) => {
    state.token = action.payload
  },

  setFingerPrint: (state: IUser, action: PayloadAction<string>) => {
    state.fingerPrint = action.payload
  },
}
