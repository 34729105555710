import axios from 'axios'

import Cookies from 'universal-cookie'

import { GLOBAL } from '../constants/Global'
import {
  AUTH_COOKIE_NAME,
  AUTH_FINGERPRINT,
  AUTH_USER_ID,
} from '../../modules/auth/cookies/authCookiesKeys'

const cookies = new Cookies()

export default axios.create({
  baseURL: `${GLOBAL.API_ARIEL_URL}/v1`,
  headers: {
    'Content-type': 'application/json',
    Authorization: `Bearer ${cookies.get(AUTH_COOKIE_NAME)}`,
    User_Id: cookies.get(AUTH_USER_ID),
    fingerprint: cookies.get(AUTH_FINGERPRINT),
  },
} as any)
