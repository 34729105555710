// import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { ROUTER } from './Router'
import { Provider } from 'react-redux'
import { store } from './app/redux/store/GlobalStore'
import ReactGA from 'react-ga4'
import './i18n'
import '../src/styles/index.scss'
import { IconContext } from 'react-icons'

// Sentry.init({
//   dsn: 'https://870bb01b52eea0004df77bc6db0fec10@o4507494652706816.ingest.us.sentry.io/4507494654935040',
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', 'https://www.arielapp.co'],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// })

ReactGA.initialize('G-4XT71BZZ2Z')

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <IconContext.Provider value={{ size: '24px' }}>
      <RouterProvider router={ROUTER} />
    </IconContext.Provider>
  </Provider>,
)
