import { createSlice } from '@reduxjs/toolkit'
import { type IDevicesLimit } from './IDevicesLimit'
import { devicesLimitReducers } from '../../reducers/devices-limit/DevicesLimitReducers'

const initialState: IDevicesLimit = {
  validDevice: false,
  devicesList: [],
  deletedDevicesList: [],
  isEmailSent: false,
}

const devicesLimitSlice = createSlice({
  name: 'devicesLimit',
  initialState,
  reducers: devicesLimitReducers,
})

export const {
  setValidDevice,
  setDeletedDevicesList,
  setDevicesList,
  setEmailSent,
} = devicesLimitSlice.actions
export default devicesLimitSlice.reducer
