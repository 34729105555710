import { createSlice } from '@reduxjs/toolkit'
import { ActiveChatReducers } from '../../../reducers/chatbot/active-chat/ActiveChatReducers'
import { type IChatHistoryItem } from '../chats-history/IChatsHistory'

export const initialActiveChatState: IChatHistoryItem = {
  uuid: '',
  name: '',
  messages: [],
  resource_id: null,
  chat_settings: null,
  suggested_questions: [],
  isReviewed: false,
  isLoadingResponse: false,
  isInPagination: true,
  isTemporary: false,
  document_text: null,
}

const activeChatSlice = createSlice({
  initialState: initialActiveChatState,
  name: 'activeChat',
  reducers: ActiveChatReducers,
})

export default activeChatSlice.reducer
export const { setActiveChat, clearActiveChat } = activeChatSlice.actions
